import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/services/loader';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  myForm: FormGroup;
  token: any;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private loader: LoaderService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
    });
    this.myForm = this.fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
  }
  resetPassword() {
    const header = new HttpHeaders(
      {
        'x-token': this.token
      }
    );
    this.http.post('auth/reset/password', this.myForm.value, { headers: header }).subscribe(
      (data: any) => {
        this.loader.state(false);
        this.router.navigate(['/']);
      },
      error => {
        this.loader.state(false);
        if (error.status === 422) {
        }
      });
  }

}
