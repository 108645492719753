import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {
  UserData: any;
  currentPage = 1;
  pageSize = 50;
  totalRecords = 0;
  all = true;
  value = '';
  UserPlanCount: any;
  ThreeMonthSubscription = false;
  SixMonthSubscription = false;
  OneMonthSubscription = false;
  OneMonthSubscriptionUsers: any;
  AllSubscriptionUsers: any;
  ThreeMonthSubscriptionUsers: any;
  SixMonthSubscriptionUsers: any;
  constructor(
    private loader: LoaderService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.getPlanCount();
    this.getAllPackage();
  }
  getPlanCount() {
    this.loader.state(true);
    this.http.get('admin/user/subscribed/statistic').subscribe(
      (respone: any) => {
        this.loader.state(false);
        console.log(respone);
        this.OneMonthSubscriptionUsers = respone.data.plan_1;
        this.ThreeMonthSubscriptionUsers = respone.data.plan_2;
        this.SixMonthSubscriptionUsers = respone.data.plan_3;
      },
      error => {
        this.loader.state(false);
        this.OneMonthSubscriptionUsers = 0;
        this.ThreeMonthSubscriptionUsers = 0;
        this.SixMonthSubscriptionUsers = 0;
      });
  }
  getAllPackage() {
    this.loader.state(true);
    const params = {
      plan: this.value,
      page: this.currentPage.toString(),
      limit: this.pageSize.toString()
    }
    this.http.get('admin/user/subscribed/plan', { params }).subscribe(
      (respone: any) => {
        this.loader.state(false);
        this.UserData = respone.data.items;
        this.totalRecords = respone.data.meta.totalItems;
        if (this.value === '') {
          this.AllSubscriptionUsers = respone.data.meta.totalItems;
        }
        if (this.value === '1') {
          this.OneMonthSubscriptionUsers = respone.data.meta.totalItems;
        }
        if (this.value === '3') {
          this.ThreeMonthSubscriptionUsers = respone.data.meta.totalItems;
        }
        if (this.value === '6') {
          this.SixMonthSubscriptionUsers = respone.data.meta.totalItems;
        }
      },
      error => {
        this.loader.state(false);
        this.UserData = [];
        if (this.value === '') {
          this.AllSubscriptionUsers = [];
        }
        if (this.value === '1') {
          this.OneMonthSubscriptionUsers = [];
        }
        if (this.value === '3') {
          this.ThreeMonthSubscriptionUsers = [];
        }
        if (this.value === '6') {
          this.SixMonthSubscriptionUsers = [];
        }
      }
    );
  }
  toggleChange(id) {
    if (id === 1) {
      this.value = '1';
      this.all = false;
      this.ThreeMonthSubscription = false;
      this.SixMonthSubscription = false;
      this.OneMonthSubscription = true;
      this.getAllPackage();
    }
    if (id === 3) {
      this.value = '3';
      this.all = false;
      this.ThreeMonthSubscription = true;
      this.SixMonthSubscription = false;
      this.OneMonthSubscription = false;
      this.getAllPackage();
    }
    if (id === 6) {
      this.value = '6';
      this.all = false;
      this.ThreeMonthSubscription = false;
      this.SixMonthSubscription = true;
      this.OneMonthSubscription = false;
      this.getAllPackage();
    }
    if (id === 'all') {
      this.value = '';
      this.all = true;
      this.ThreeMonthSubscription = false;
      this.SixMonthSubscription = false;
      this.OneMonthSubscription = false;
      this.getAllPackage();
    }
  }
  onPageChange(currentPage: number) {
    this.currentPage = currentPage;
    this.getAllPackage();
  }
}
