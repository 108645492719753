import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/services/loader';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {
  myForm: FormGroup;
  email: any;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private loader: LoaderService,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.email = params.email;
    });
    this.myForm = this.fb.group({
      code: ['', [Validators.required,Validators.minLength(6)]],
      role: "admin",
      email: this.email
    });
  }
  onOtpChange(event) {
    this.myForm.get('code').setValue(event);
  }
  verifyPassword() {
    this.http.post('auth/verify/code', this.myForm.value).subscribe(
      (data: any) => {
        this.loader.state(false);
        this.router.navigate(['/reset-password'], { queryParams: { token: data.token } });
      },
      error => {
        this.loader.state(false);
        if (error.status === 422) {
        }
      });
  }
  resendOtp() {
    this.loader.state(true);
    this.http.post('auth/forgot/password', { email: this.email, role: 'admin' }).subscribe(
      (data: any) => {
        this.loader.state(false);
      },
      error => {
        this.loader.state(false);
      }
    )
  }
}
