import { Component, OnInit } from "@angular/core";
import { LoaderService } from "src/app/services/loader";
import { HttpClient } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../environments/environment.prod";
@Component({
  selector: "app-banners",
  templateUrl: "./mp3.component.html",
  styleUrls: ["./mp3.component.scss"],
})
export class Mp3Component implements OnInit {
  public Categories: any;
  public Coupons: any;
  addCoupon: FormGroup;
  couponTitle = "Add Audio";
  categories: any;
  bookActiveId: number;
  audioIdEditing: string = "";
  file: any;
  books: any;
  searchData: any;
  totalAudio: any;
  currentPage = 1;
  pageSize = 10;
  token: string = localStorage.getItem("authtoken") || "";
  apiUrl = environment.apiUrl;
  constructor(
    private loader: LoaderService,
    private http: HttpClient,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.getAllAudios();
    this.getCategories();
    this.totalAudio = 0;
    this.addCoupon = this.fb.group({
      title: ["", [Validators.required]],
      url: ["", [Validators.required]],
      bookId: ["", [Validators.required]],
      chapterId: ["", [Validators.required]],
    });
  }

  getCategories() {
    this.loader.state(true);
    this.http.get("admin/book/list/for-select").subscribe(
      (response: any) => {
        this.loader.state(false);
        this.categories = response.data;
      },
      (error) => {
        this.loader.state(false);
      }
    );
  }

  getBooks() {
    this.loader.state(true);
    this.http.get(`admin/chapter/list-by/${this.bookActiveId}`).subscribe(
      (response: any) => {
        this.loader.state(false);
        let temData = response.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
        this.books = temData;
      },
      (error) => {
        this.loader.state(false);
        this.books = [];
      }
    );
  }

  getAllAudios(search: string = this.searchData) {
    this.loader.state(true);
    const params = {
      search: "",
      page: this.currentPage.toString(),
      limit: this.pageSize.toString(),
    };
    if (search) {
      params.search = search;
    }

    this.http.get("admin/mp3-url", { params }).subscribe(
      (response: any) => {
        this.loader.state(false);
        this.Categories = response.data.items;
        this.totalAudio = response.data.meta.totalItems;
      },
      (error) => {
        this.loader.state(false);
      }
    );
  }

  getAudioById(addCouponModal, audioId: string) {
    this.audioIdEditing = audioId;
    this.loader.state(true);
    this.couponTitle = "Update Audio";
    this.modalService.open(addCouponModal, {
      size: "xl",
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "modal-holder",
    });
    this.http.get(`admin/mp3-url/${audioId}`).subscribe(
      (response: any) => {
        this.loader.state(false);
        const { title, url, bookId, chapterId } = response.data;
        if (chapterId) {
          this.bookActiveId = bookId;
          this.getBooks();
        }
        this.addCoupon.get("title").setValue(title);
        this.addCoupon.get("url").setValue(url);
        this.addCoupon.get("bookId").setValue(bookId);
        this.addCoupon.get("chapterId").setValue(chapterId);
      },
      (error) => {
        this.loader.state(false);
      }
    );
  }

  onChangeCategory(rowData) {
    if (Number(rowData.split(":")[1])) {
      this.bookActiveId = Number(rowData.split(":")[1]);
      this.getBooks();
    }
  }

  onPageChange(currentPage: number) {
    this.currentPage = currentPage;
    this.getAllAudios();
  }

  openCategoryModal(addCouponModal) {
    this.addCoupon.get("title").setValue("");
    this.addCoupon.get("url").setValue("");
    this.addCoupon.get("bookId").setValue("");
    this.addCoupon.get("chapterId").setValue("");
    this.audioIdEditing = "";
    this.modalService.open(addCouponModal, {
      size: "xl",
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "modal-holder",
    });
  }

  async addAudioValue() {
    if(this.file){
      this.loader.state(true);
      await this.upload()
        .then((response) => response.json())
        .then((response: any) => {
          this.addCoupon.get("url").setValue(response.data.filename);
          this.file = '';
          this.saveAudio();
        })
        .catch((error) => {
          this.addCoupon.get("url").setValue('');
          this.file = '';
          console.log("error", error)
          this.loader.state(false);
        });
    }else{
      this.saveAudio();
    }
  }
  async upload() {
    if (this.file) {
      const headers = new Headers();
     headers.append("Authorization", `Bearer ${this.token}`);

      const formData = new FormData();
      formData.append("file", this.file, this.file.name);
      const requestOptions: any = {
        method: "POST",
        headers: headers,
        body: formData,
        redirect: "follow",
      };
      const { bookId, chapterId } = this.addCoupon.value;
      return await fetch(
        `${this.apiUrl}/admin/mp3-url/upload?bookId=${bookId}&chapterId=${chapterId}`,
        requestOptions
      );
    }
  }

  saveAudio() {
    if (this.audioIdEditing) {
      this.http
        .put(`admin/mp3-url/${this.audioIdEditing}`, this.addCoupon.value)
        .subscribe(
          (data: any) => {
            this.loader.state(false);
            this.modalService.dismissAll();
            this.getAllAudios();
            this.audioIdEditing = "";
          },
          (error) => {
            this.loader.state(false);
          }
        );
    } else {
      this.http.post("admin/mp3-url", this.addCoupon.value).subscribe(
        (data: any) => {
          this.loader.state(false);
          this.modalService.dismissAll();
          this.getAllAudios();
        },
        (error) => {
          this.loader.state(false);
        }
      );
    }
  }

  deleteCoupon(couponId: string) {
    if (confirm("Are you sure you want to delete?")) {
      this.loader.state(true);
      this.http.delete("admin/mp3-url/" + parseInt(couponId)).subscribe(
        (data) => {
          this.loader.state(false);
          this.getAllAudios();
        },
        (error) => {
          this.loader.state(false);
        }
      );
    }
  }

  Search(data) {
    this.getAllAudios(data);
  }
  SearchOnChange(data) {
    if (data == "") {
      this.getAllAudios(data);
    }
  }

  selectFile(files) {
    if (files) {
      this.file = files[0];
      this.addCoupon.get("url").setValue(files[0].name);
    }
  }
}
