import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-coupon',
  templateUrl: './user-subscription-log.component.html',
  styleUrls: ['./user-subscription-log.component.scss']
})
export class UserSubscriptionLogComponent implements OnInit {
  public usersSubscription: any;
  public userId: any;
  public userSubscriptionRecordId: any;
  public userInfo = {};
  userSubscriptionStatus : any;
  couponId: any;
  searchData: any;
  totalUserSubscription: any;
  issuedDate: any = null;
  channelActive: number;
  currentPage = 1;
  pageSize = 10;
  constructor(
    private loader: LoaderService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.getAllUserSubscriptionLog();
    this.totalUserSubscription = 0;
    this.userSubscriptionStatus = false;
    
  }

  onCleanFieldFilter(){
    if (confirm('Are you sure you want to clear user subscription log?')) {
      this.loader.state(true);
      this.http.delete('admin/user-subcription/clear/failed-log').subscribe(
        data => {
          this.loader.state(false);
          this.getAllUserSubscriptionLog();
        },
        error => {
          this.loader.state(false);
        }
      );
    }
  }

  onDeleteLogRecord(logId: string){
    if (confirm('Are you sure you want to delete user subscription log?')) {
      this.loader.state(true);
      this.http.delete('admin/user-subcription/delete/failed-log/' + parseInt(logId)).subscribe(
        data => {
          this.loader.state(false);
          this.getAllUserSubscriptionLog();
        },
        error => {
          this.loader.state(false);
        }
      );
    }
  }

  getAllUserSubscriptionLog(search: string = this.searchData) {
    this.loader.state(true); 
    const params: any = {
      search: '',
      page: this.currentPage.toString(),
      limit: this.pageSize.toString(),
    }
    if (search) {
      params.search = search
    }
    if(this.issuedDate​​){
      const month = this.issuedDate.month < 10 ? `0${this.issuedDate.month}`: this.issuedDate.month;
      const day = this.issuedDate.day < 10 ? `0${this.issuedDate.day}`: this.issuedDate.day;
      params.date = `${this.issuedDate.year}-${month}-${day}`
    }

    let requestUrl = `admin/user-subcription/list/failed-log`;
    this.http.get(requestUrl, {params}).subscribe(
      (response: any) => {
        this.loader.state(false);
        this.usersSubscription = response.data.items;
        this.totalUserSubscription = response.data.meta.totalItems;
      },
      error => {
        this.loader.state(false);
        this.usersSubscription = [];
        this.totalUserSubscription = 0;
      }
    );
  }

  onPageChange(currentPage: number){
    this.currentPage = currentPage;
    this.getAllUserSubscriptionLog();
  }
  
  Search(data) {
    this.getAllUserSubscriptionLog(data);
  }
  SearchOnChange(data) {
    if (data == '') {
      this.getAllUserSubscriptionLog(data);
    }
  }

  issuedDateChange(){
    this.getAllUserSubscriptionLog();
  }

  clearSearchDate(){
    this.issuedDate = null;
    this.getAllUserSubscriptionLog();
  }
}
