import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/services/loader';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  myForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private loader: LoaderService,
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.myForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('[a-z0-9.@]*')]],
      role: "admin"
    });
  }
  onSubmit() {
    console.log('inside', this.myForm.value)
    this.loader.state(true);
    this.http.post('auth/forgot/password', this.myForm.value).subscribe(
      (data: any) => {
        console.log('sucess', data)
        this.loader.state(false);
        this.router.navigate(['/otp-verification'], { queryParams: { email: this.myForm.value.email } });
      },
      error => {
        this.loader.state(false);
        if (error.status === 422) {
        }
      }
    )
  }

}
