import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../../app/services/loader';

@Component({
  selector: 'app-user-exchange',
  templateUrl: './user-exchange.component.html',
  styleUrls: ['./user-exchange.component.scss']
})
export class UserExchangeComponent implements OnInit {

  userExchanges: any[] = [];
  currentPage = 1;
  pageSize = 10;
  totalRecords = 0;
  exchangeDate: any = null;

  constructor(private loader: LoaderService,
    private http: HttpClient) { }

  ngOnInit() {
    this.get();
  }

  get(){
    this.loader.state(true);
    const params: any = {
      page: this.currentPage.toString(),
      limit: this.pageSize.toString()
    };
    if(this.exchangeDate){
      const month = this.exchangeDate.month < 10 ? `0${this.exchangeDate.month}`: this.exchangeDate.month;
      const day = this.exchangeDate.day < 10 ? `0${this.exchangeDate.day}`: this.exchangeDate.day;
      params.createdAt = `${this.exchangeDate.year}-${month}-${day}`
    }
    this.http.get('admin/user-exchange', { params }).subscribe(
      (respone: any) => {
        this.userExchanges = respone.data.items;
        this.userExchanges.forEach(userExchange => {
          userExchange.commissionGross = (userExchange.amount * userExchange.exchangeCode.commissionFee) / 100;
          userExchange.commissionTax = userExchange.commissionGross * 0.15;
          userExchange.commissionNet = userExchange.commissionGross - userExchange.commissionTax;
        });
        this.totalRecords = respone.data.meta.totalItems;
        this.loader.state(false);
      },
      error => {
        this.loader.state(false);
        this.userExchanges = [];
        this.totalRecords = 0;
      }
    );
  }
  onPageChange(currentPage){
    this.currentPage = currentPage;
    this.get();
  }

  exchangeDateChange(){
    this.get();
  }

  clearSearchDate(){
    this.exchangeDate = null;
    this.get();
  }
}
