import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private info = new BehaviorSubject<boolean>(false);
    public role = new BehaviorSubject<any>({});

    private currentUser;
    private currentToken;
    private currentKey;

    currentState = this.info.asObservable();

    guest() {

        if (this.token() && this.user()) {
            this.state(false);
            return false;
        }

        this.state(true);
        return true;
    }

    user() {
        let user = localStorage.getItem('_currentUser');

        return JSON.parse(user);
    }

    state(state: boolean) {
        this.info.next(state);
    }

    token() {
        return localStorage.getItem('authtoken') || '';
    }

    login(data: any, remeber = false) {
        this.state(true);

        this.currentUser = data.user;
        this.currentToken = data.access_token;

        let user = JSON.stringify(data.user);
        let token = data.access_token;

        localStorage.setItem('_currentUser', user);
        localStorage.setItem('authtoken', token);

    }

    logout() {

        // remove local storage
        localStorage.removeItem('_currentUser');
        localStorage.removeItem('authtoken');
    }
}
