import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-coupon',
  templateUrl: './user-subscription.component.html',
  styleUrls: ['./user-subscription.component.scss']
})
export class UserSubscriptionComponent implements OnInit {
  public usersSubscription: any;
  public userId: any;
  public userSubscriptionRecordId: any;
  public userInfo = {};
  userSubscriptionStatus : any;
  addUserSubscription: FormGroup;
  addUserSubscriptionModal: FormGroup;
  couponId: any;
  searchData: any;
  totalUserSubscription: any;
  channelsData: any;
  statusData = [
    { id: 'pending', name: 'Pending' },
    { id: 'success', name: 'Success' }
  ];
  durationData = [
    { id: 1,  name: "1 Month"},
    { id: 3,  name: "3 Month"},
    { id: 6,  name: "6 Month"},
    { id: 12, name: "12 Month"}
  ]
  channelActive: number;
  statusActive: any;
  currentPage = 1;
  pageSize = 10;
  constructor(
    private activatedRoute: ActivatedRoute,
    private loader: LoaderService,
    private http: HttpClient,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((p) => {
      this.couponId = p.couponId;
    });
    this.getAllUserSubscription();
    this.getAllChannelsFilter();
    this.totalUserSubscription = 0;
    this.userSubscriptionStatus = false;
    this.channelsData = [];
    this.statusActive = "";
    this.addUserSubscription = this.fb.group({
      channelId: ['', [Validators.required]],
      statusId: ['', [Validators.required]],
      search: ['', [Validators.required]],
    });

    this.addUserSubscriptionModal = this.fb.group({
      amount: ['', [Validators.required]],
      duration: ['', [Validators.required]],
    });
  }

  getAllChannelsFilter() {
    this.loader.state(true);
    this.http.get("admin/channel?limit=200").subscribe(
      (response: any) => {
        let data = response.data.items.map((item)=> {
          return { name: item.name,id: item.id }
        })
        this.channelsData = data;
        this.loader.state(false);
      },
      (error) => {
        this.loader.state(false);
      }
    );
  }

  addSubscriptionUser(modalUserSubscription, user: any,) {
    const { amount, duration, id, status} = user;

    this.userInfo = user;
    this.userSubscriptionStatus = status;
    this.userId = user.user.id;
    this.userSubscriptionRecordId = id;
    this.addUserSubscriptionModal.get('duration').setValue(duration);
    this.addUserSubscriptionModal.get('amount').setValue(amount);
    this.modalService.open(modalUserSubscription, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  addUserSubscriptionModalValue(){
    let { duration, amount } = this.addUserSubscriptionModal.value;
    let data = { 
      userId: this.userId, 
      duration: duration, 
      amount: +amount,
    };

    this.http.put(`admin/user-subcription/confirm/${this.userSubscriptionRecordId}`, data).subscribe(
    (data: any) => {
      this.loader.state(false);
      this.modalService.dismissAll();
      this.getAllUserSubscription();
    },
    error => {
      this.loader.state(false);
    });
  }

  onChangeChannel(rowData){
    if(Number(rowData.split(":")[1])){
      this.channelActive  = Number(rowData.split(":")[1]);
      this.getAllUserSubscription();
    }
  }

  onChangeStatus(rowData){
    if(rowData.split(":")[1]){
      this.statusActive  = rowData.split(":")[1];
      this.getAllUserSubscription();
    }
  }

  onCleanFieldFilter(){
    this.channelActive = null;
    this.searchData = "";
    this.statusActive = "";
    this.addUserSubscription.get('channelId').setValue('')
    this.addUserSubscription.get('search').setValue('')
    this.addUserSubscription.get('statusId').setValue('')
    this.getAllUserSubscription();
  }

  onDeactivateSubscriptionUser(subscriptionId: string){
    if (confirm("Do you want to deactivate this user?")) {
      this.loader.state(true);
      this.http
        .put(`admin/user-subcription/user-deactivate/channel-subscription/${subscriptionId}` , {})
        .subscribe(
          (data: any) => {
            this.loader.state(false);
            this.getAllUserSubscription();
          },
          (error) => {
            this.loader.state(false);
          }
        );
    }
  }

  getAllUserSubscription(search: string = this.searchData) {
    this.loader.state(true); 
    const params = {
      search: '',
      page: this.currentPage.toString(),
      limit: this.pageSize.toString(),
    }
    if (search) {
      params.search = search
    }
    let requestUrl = `admin/user-subcription?${this.statusActive?`status=${this.statusActive}`:''}${this.channelActive?`&channelId=${this.channelActive}`:''}`.replace(/\s/g, '');
    this.http.get(requestUrl, {params}).subscribe(
      (response: any) => {
        this.loader.state(false);
        this.usersSubscription = response.data.items;
        this.totalUserSubscription = response.data.meta.totalItems;
      },
      error => {
        this.loader.state(false);
        this.usersSubscription = [];
        this.totalUserSubscription = 0;
      }
    );
  }

  onPageChange(currentPage: number){
    this.currentPage = currentPage;
    this.getAllUserSubscription();
  }
  
  Search(data) {
    this.getAllUserSubscription(data);
  }
  SearchOnChange(data) {
    if (data == '') {
      this.getAllUserSubscription(data);
    }
  }
}
