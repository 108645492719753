import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { AlertService } from './alert';
import { Router } from '@angular/router';


@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
    apiReq: any;
    constructor(
        private auth: AuthService,
        private router: Router,
        private alertService: AlertService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // document.getElementById('CustomLoader').innerHTML = `<div id="preloader"><div id="loader"></div></div>`;

        let apiReq = req.clone({
            url: `${environment.apiUrl}/${req.url}`,
        });

        if (!this.auth.guest()) {
            let headerOption: any = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.auth.token()
            };
            if(req.url.includes('media')){
                headerOption = {
                    'Authorization': 'Bearer ' + this.auth.token()
                };
            }
            apiReq = apiReq.clone(
                {
                    headers: new HttpHeaders(headerOption)
                });
        }


        return next.handle(apiReq).pipe(tap(event => {
            if (event instanceof HttpResponse) {
                // document.getElementById('CustomLoader').innerHTML = '';
                if (event.body.message) {
                    this.alertService.state({ text: event.body.message });
                }
            }
        },
            error => {
                // document.getElementById('CustomLoader').innerHTML = '';
                //console.log('error in interceptor', error);
                // console.error(error);

                if (error.status === 422) {
                    console.log('error',error.error.errors.image)
                    if(error.error.errors.image){
                        this.alertService.state({ text: error.error.errors.image.message, type: 'danger' });
                        return;
                    }else if(error.error.errors.audioUrl){
                        this.alertService.state({ text: error.error.errors.audioUrl.message, type: 'danger' });
                        return;
                    }else if(error.error.errors.confirmNewPassword){
                        this.alertService.state({ text: error.error.errors.confirmNewPassword.message, type: 'danger' });
                        return;
                    }else if (error.error.errors.newPassword){
                        this.alertService.state({ text: error.error.errors.newPassword.message, type: 'danger' });
                        return
                    }else if (error.error.errors.oldPassword){
                        this.alertService.state({ text: error.error.errors.oldPassword.message, type: 'danger' });
                        return
                    }
                    else if (error.error.errors.email){
                        this.alertService.state({ text: error.error.errors.email.message, type: 'danger' });
                        return
                    }
                    else if (error.error.errors.code){
                        this.alertService.state({ text: error.error.errors.code.message, type: 'danger' });
                        return
                    }else if(error.error.errors.confirmPassword){
                        this.alertService.state({ text: error.error.errors.confirmPassword.message, type: 'danger' });
                        return;
                    }else if (error.error.errors.password){
                        this.alertService.state({ text: error.error.errors.password.message, type: 'danger' });
                        return
                    }
                    else{
                    this.alertService.state({ text: 'Please correct the highlighted inputs.', type: 'danger' });
                    return;
                    }
                }

                if (error.status === 406) {
                    this.alertService.state({ text: error.error.message || 'Internal server error', type: 'danger' });
                    return;
                }

                if (error.error) {
                    this.alertService.state({ text: error.error.message || 'Internal server error', type: 'danger' });
                }

                // setTimeout(() => {
                //     this.indicator.spinner(false);
                // }, 2000);

                // const info = new HttpInfo(error.status, error.error.error_description || error.statusText, 0);

                if ((error.status === 401)) {
                    // info.message = 'Session is invalid or expired. Please try loggin in again.';

                    this.auth.logout();

                    setTimeout(() => {
                        this.router.navigate([''], { queryParams: { ref: 'user' } });
                    }, 500);
                    this.alertService.state({ text: error.error.message || 'Internal server error', type: 'danger' });
                    return;
                }
            }));
    }
}