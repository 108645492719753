import { Component, OnInit } from '@angular/core';
import { AlertService } from './services/alert';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { LoaderService } from './services/loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'bookeyond';

  loading = false;
  alert: any = {
    text: 'alert working'
  };

  previousAlertTimer;

  constructor(
    private loader: LoaderService,
    private router: Router,
    private alertService: AlertService
  ) {

    this.alertService.attach.subscribe(data => {
      if (this.previousAlertTimer) {
        clearTimeout(this.previousAlertTimer);
      }

      this.alert = data;

      this.previousAlertTimer = setTimeout(() => {
        this.alert = { text: null };
      }, 5000);
    });
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loader.state(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loader.state(false);
      }
    });
  }

  ngAfterViewInit() {
    this.loader.attach.subscribe(data => {
      setTimeout(() => {
        this.loading = data;
      }, 100);
    });
  }

}


