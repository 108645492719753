import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../../app/services/loader';

@Component({
  selector: 'app-user-top-up',
  templateUrl: './user-top-up.component.html',
  styleUrls: ['./user-top-up.component.scss']
})
export class UserTopUpComponent implements OnInit {

  userTopups: any[] = [];
  currentPage = 1;
  pageSize = 10;
  totalRecords = 0;
  issuedDate: any = null;

  constructor(private loader: LoaderService,
    private http: HttpClient) { }

  ngOnInit() {
    this.get();
  }

  get(){
    this.loader.state(true);
    const params: any = {
      page: this.currentPage.toString(),
      limit: this.pageSize.toString()
    };
    if(this.issuedDate){
      const month = this.issuedDate.month < 10 ? `0${this.issuedDate.month}`: this.issuedDate.month;
      const day = this.issuedDate.day < 10 ? `0${this.issuedDate.day}`: this.issuedDate.day;
      params.issuedAt = `${this.issuedDate.year}-${month}-${day}`
    }
    this.http.get('admin/user-topup', { params }).subscribe(
      (respone: any) => {
        this.userTopups = respone.data.items;
        this.totalRecords = respone.data.meta.totalItems;
        this.loader.state(false);
      },
      error => {
        this.loader.state(false);
        this.userTopups = [];
        this.totalRecords = 0;
      }
    );
  }
  onPageChange(currentPage){
    this.currentPage = currentPage;
    this.get();
  }

  issuedDateChange(){
    this.get();
  }

  clearSearchDate(){
    this.issuedDate = null;
    this.get();
  }
}
