import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service'
import { LoaderService } from 'src/app/services/loader';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  toggleMenu: any;
  change: FormGroup;
  userName: any;
  userId: any;
  constructor(
    private loader: LoaderService,
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.userName = this.auth.user().name;
    this.userId = this.auth.user().id;
    this.change = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmNewPassword: ['', [Validators.required]]
    });
  }
  logout(id: number) {
    if (id == 0) {
      this.loader.state(true);
      this.http.get('auth/logout').subscribe(
        data => {
          this.auth.logout();
          this.loader.state(false);
          this.router.navigate(['/']);
        },
        error => {
          this.loader.state(false);
        }
      );
    } else {
      if (confirm('Are you sure you want to logout?')) {
        this.loader.state(true);
        this.http.get('auth/logout').subscribe(
          data => {
            this.auth.logout();
            this.loader.state(false);
            this.router.navigate(['/']);
          },
          error => {
            this.loader.state(false);
          }
        );
      }
    }
  }
  chagePassowrd(chagePassword) {
    this.change.get('oldPassword').setValue('');
    this.change.get('newPassword').setValue('');
    this.change.get('confirmNewPassword').setValue('');
    this.modalService.open(chagePassword, { centered: true, windowClass: 'modal-holder' });
  }
  changePasswords() {
    if(this.change.get('newPassword').value != this.change.get('confirmNewPassword').value){
      alert('New password mismatch with confirm password!');
      return;
    }

    this.http.put('admin/user/change-password/' + this.userId , this.change.value).subscribe(
      (data: any) => {
        setTimeout(() => {
          this.modalService.dismissAll();
          this.logout(0);
        }, 2000);

      },
      error => {
        if (error.status === 422) {
        }
        else if (error.status === 400) {
        }
      });
  }
}
