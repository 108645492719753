import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service'
import { LoaderService } from 'src/app/services/loader';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  myForm: FormGroup;
  errors: any = {};
  constructor(
    private fb: FormBuilder,
    private loader: LoaderService,
    private http: HttpClient,
    private auth: AuthService,
    private router: Router
  ) { }
  ngOnInit() {
    this.myForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }
  login() {
    this.loader.state(true);
    this.http.post('auth/login', this.myForm.value).subscribe(
      (data: any) => {
        this.loader.state(false);
        this.auth.login(data);
        this.router.navigate(['/dashboard']);
      },
      error => {
        this.loader.state(false);
        if (error.status === 422) {
          this.errors = error.error.errors;
        }
      }
    )
  }
}
