import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../services/loader';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-purchase-report',
  templateUrl: './purchase-report.component.html',
  styleUrls: ['./purchase-report.component.scss']
})
export class PurchaseReportComponent implements OnInit {

  userPurchaseReport: any[] = [];
  currentPage = 1;
  pageSize = 10;
  totalRecords = 0;
  searchData: string = '';
  startDate: any = null;
  endDate: any = null;
  filterStartDate: any = null;
  filterEndDate: any = null;

  constructor(private loader: LoaderService,
    private http: HttpClient) { }

  ngOnInit() {
    this.get();
  }

  get(search: string = this.searchData){
    this.loader.state(true);
    const params: any = {
      search: '',
      page: this.currentPage.toString(),
      limit: this.pageSize.toString()
    };
    if (search) {
        params.search = search,
        params.page = this.currentPage.toString(),
        params.limit = '10';
    }

    if(this.startDate){
      const { day: startDay, month: startMonth, year: startYear} = this.startDate;
      this.filterStartDate = `${startYear}-0${startMonth}-0${startDay}`;
    }

    if(this.endDate){
      const { day: startDay, month: startMonth, year: startYear} = this.endDate;
      this.filterEndDate = `${startYear}-0${startMonth}-0${startDay}`;
    }
    
    this.http.get(`admin/user-purchase/report?${this.filterStartDate?`startDate=${this.filterStartDate}`:''}${this.filterEndDate?`&endDate=${this.filterEndDate}`:''}`, { params }).subscribe(
      (response: any) => {
        this.userPurchaseReport = response.data.items;
        this.totalRecords = response.data.meta.totalItems;
        this.loader.state(false);
      },
      error => {
        this.loader.state(false);
        this.userPurchaseReport = [];
        this.totalRecords = 0;
        console.log('[error]', error)
      }
    );
  }

  downloadExcelDetail(){
    let element = document.getElementById('excel-table-detail'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     XLSX.writeFile(wb, "purchase-report.xlsx");
  }

  onPageChange(currentPage){
    this.currentPage = currentPage;
    this.get();
  }

  issuedDateChange(){
    this.get();
  }

  Search(data){
    this.get(data);
  }

  clearSearchDate(){
    if( this.endDate || this.startDate){
      this.endDate = null;
      this.startDate = null;
      this.filterStartDate = null;
      this.filterEndDate = null;
      this.get();
    }
  }
}
