import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { RequestInterceptorService } from './services/http.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './shared/loader/loader.component';
import { AlertService } from './services/alert';
import { LoginComponent } from './components/guest-layout/login/login.component';
import { GuestLayoutBackgroundComponent } from './components/guest-layout/guest-layout-background/guest-layout-background.component';
import { ForgotPasswordComponent } from './components/guest-layout/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/guest-layout/reset-password/reset-password.component';
import { NavbarComponent } from './components/main-layout/navbar/navbar.component';
import { DashboardComponent } from './components/main-layout/dashboard/dashboard.component';
import { BannersComponent } from './components/main-layout/banners/banners.component';
import { Mp3Component } from './components/main-layout/mp3/mp3.component';
import { CategoryComponent } from './components/main-layout/category/category.component';
import { UserSubscriptionComponent } from './components/main-layout/user-subscription/user-subscription.component';
import { UserSubscriptionLogComponent } from './components/main-layout/user-subscription-log/user-subscription-log.component';
import { OtpVerificationComponent } from './components/guest-layout/otp-verification/otp-verification.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { QuillModule } from 'ngx-quill';
import { PackagesComponent } from './components/main-layout/packages/packages.component';
import { UserTopUpComponent } from './components/main-layout/user-top-up/user-top-up.component';
import { PurchaseReportComponent } from './components/main-layout/purchase-report/purchase-report.component';
import { UserExchangeComponent } from './components/main-layout/user-exchange/user-exchange.component';
import { EarningHistoryComponent } from './components/main-layout/earning-history/earning-history.component';
import { DiscountReportComponent } from './components/main-layout/discount-report/discount-report.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    LoginComponent,
    GuestLayoutBackgroundComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NavbarComponent,
    DashboardComponent,
    BannersComponent,
    Mp3Component,
    CategoryComponent,
    UserSubscriptionComponent,
    UserSubscriptionLogComponent,
    OtpVerificationComponent,
    PackagesComponent,
    UserTopUpComponent,
    PurchaseReportComponent,
    DiscountReportComponent,
    UserExchangeComponent,
    EarningHistoryComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgOtpInputModule,
    ImageCropperModule,
    DragDropModule,
    QuillModule.forRoot()
  ],
  providers: [AlertService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }
