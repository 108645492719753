import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  banners: any = [];
  bannerData: any;
  myForm: FormGroup;
  imageChangedEvent: any = '';
  image: any = '';
  imageID: any;
  imgUrl = environment.imgUrl;
  constructor(
    private fb: FormBuilder,
    private loader: LoaderService,
    private http: HttpClient,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.myForm = this.fb.group({
      bannerImage: ['', [Validators.required]],
    });
    this.getAllBanners();
  }
  getAllBanners() {
    this.loader.state(true);
    this.http.get('admin/banner').subscribe(
      (respone: any) => {
        this.loader.state(false);
        this.bannerData = respone.data.items;
      },
      error => {
        this.loader.state(false);
      }
    );
  }
  deleteBanner(id: string) {
    if (confirm('Are you sure you want to delete this Banner?')) {
      this.loader.state(true);
      this.http.delete('admin/banner/' + id).subscribe(
        data => {
          this.loader.state(false);
          this.getAllBanners();
        },
        error => {
          this.loader.state(false);
        }
      );
    }
  }
  fileChangeEvent(event: any, cropbanner): void {
    this.openVerticallyCentered(cropbanner);
    this.imageChangedEvent = event;
  }
  openVerticallyCentered(cropbanner) {
    this.modalService.open(cropbanner, { centered: true, size: 'lg', backdrop: 'static', keyboard: false, windowClass: 'modal-holder' });
  }
  imageCropped(event: ImageCroppedEvent) {
    this.image = event.base64;
  }
  uploadCroppedImg() {
    this.uploadMediaImgFile(this.image);
  }
  //used for convert base 64 to blob type
  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 256;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  imageLoaded() {
  }
  cropperReady() {
  }
  loadImageFailed() {
  }
  uploadMediaImgFile(file) {
    var block = file.split(";");
    var contentType = block[0].split(":")[1];
    var realData = block[1].split(",")[1];
    var blob = this.b64toBlob(realData, contentType, 256);
    const formData = new FormData();
    if (file) {
      formData.append('file', blob);
      formData.append('type', 'image');
      this.loader.state(true);
      this.http.post('admin/banner/media', formData).subscribe(
        (respone: any) => {
          this.loader.state(false);
          this.imageID = respone.data.filename;
        },
        error => {
          this.loader.state(false);
        }
      );
    }
  }
  drop(event: CdkDragDrop<any>) {
    this.banners = [];
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    for (let i = 0; i < event.container.data.length; i++) {
      const banner = {
        id: event.container.data[i].id,
        order: i
      }
      this.banners.push(banner);
    }

    this.http.put('admin/banner/update/order', { 'orders': this.banners }).subscribe(
      (data: any) => {
        this.loader.state(false);
      },
      error => {
        this.loader.state(false);
      }
    );
  }
  submit() {
    this.loader.state(true);
    const param = {
      image: this.imageID
    }
    this.http.post('admin/banner', param).subscribe(
      (data: any) => {
        this.loader.state(false);
        this.image = '';
        this.imageID = '';
        this.myForm.get('bannerImage').setValue('');
        this.getAllBanners();
      },
      error => {
      }
    );
  }

}
