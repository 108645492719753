import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GuestLayoutBackgroundComponent } from "./components/guest-layout/guest-layout-background/guest-layout-background.component";
import { GuestGuard } from "./guards/guest.guard";
import { AuthGuard } from "./guards/auth.guard";
import { LoginComponent } from "./components//guest-layout/login/login.component";
import { ForgotPasswordComponent } from "./components/guest-layout/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components//guest-layout/reset-password/reset-password.component";
import { NavbarComponent } from "./components/main-layout/navbar/navbar.component";
import { DashboardComponent } from "./components/main-layout/dashboard/dashboard.component";
import { BannersComponent } from "./components/main-layout/banners/banners.component";
import { CategoryComponent } from "./components/main-layout/category/category.component";
import { UserSubscriptionComponent } from "./components/main-layout/user-subscription/user-subscription.component";
import { UserSubscriptionLogComponent } from "./components/main-layout/user-subscription-log/user-subscription-log.component";
import { OtpVerificationComponent } from "./components/guest-layout/otp-verification/otp-verification.component";
import { PackagesComponent } from "./components/main-layout/packages/packages.component";
import { UserTopUpComponent } from "./components/main-layout/user-top-up/user-top-up.component";
import { PurchaseReportComponent } from "./components/main-layout/purchase-report/purchase-report.component";
import { DiscountReportComponent } from "./components/main-layout/discount-report/discount-report.component";
import { UserExchangeComponent } from "./components/main-layout/user-exchange/user-exchange.component";
import { EarningHistoryComponent } from "./components/main-layout/earning-history/earning-history.component";
import { Mp3Component } from "./components/main-layout/mp3/mp3.component";

const routes: Routes = [
  {
    path: "",
    component: GuestLayoutBackgroundComponent,
    canActivate: [GuestGuard],
    children: [
      {
        path: "",
        component: LoginComponent,
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
      },
      {
        path: "reset-password",
        component: ResetPasswordComponent,
      },
      {
        path: "otp-verification",
        component: OtpVerificationComponent,
      },
    ],
  },
  {
    path: "",
    component: NavbarComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "books",
        loadChildren: "./components/main-layout/books/books-module#BookModule",
      },
      {
        path: "channels",
        loadChildren: "./components/main-layout/channels/channels-module#ChannelsModule",
      },
      {
        path: "coupons",
        loadChildren: "./components/main-layout/coupons/coupons-module#CouponsModule",
      },
      {
        path: "testimonial",
        loadChildren:
          "./components/main-layout/testimonial/testimonial-module#TestimonialModule",
      },
      {
        path: "auto-notification",
        loadChildren:
          "./components/main-layout/auto-notification/auto-notification-module#AutoNotificationModule",
      },
      {
        path: "dashboard",
        component: DashboardComponent,
      },
      {
        path: "users",
        loadChildren: "./components/main-layout/users/user-module#UserModule",
      },
      {
        path: "earning-history",
        component: EarningHistoryComponent,
      },
      {
        path: "user-top-up",
        component: UserTopUpComponent,
      },
      {
        path: "purchase-report",
        component: PurchaseReportComponent,
      },
      {
        path: "user-exchange",
        component: UserExchangeComponent,
      },
      {
        path: "discount-report",
        component: DiscountReportComponent,
      },
      {
        path: "exchange-code-manager",
        loadChildren:
          "./components/main-layout/exchange-code-manager/exchange-code-manager#ExchangeCodeManagerModule",
      },
      {
        path: "notification-group",
        loadChildren:
          "./components/main-layout/notificationgroup/notification-group#NotificationGroupModule",
      },
      {
        path: "banners",
        component: BannersComponent,
      },
      {
        path: "audio",
        component: Mp3Component,
      },
      {
        path: "category",
        component: CategoryComponent,
      },
      {
        path: "user-subscription",
        component: UserSubscriptionComponent,
      },
      {
        path: "user-subscription/log",
        component: UserSubscriptionLogComponent,
      },
      {
        path: "package",
        component: PackagesComponent,
      },
      {
        path: "notification",
        loadChildren:
          "./components/main-layout/notifications/notifications#NotificationsModule",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

