import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader';

@Component({
  selector: 'app-earning-history',
  templateUrl: './earning-history.component.html',
  styleUrls: ['./earning-history.component.scss']
})
export class EarningHistoryComponent implements OnInit {

  earnigAndPayments: any[] = [];
  currentPage = 1;
  pageSize = 10;
  totalRecords = 0;
  excManager: string = "";
  exchangeCodeManagers: any[] = [];
  excManagerPageSize: number = 10;
  excManagerCurrentPage: number = 1;

  constructor(private loader: LoaderService,
    private http: HttpClient) { }

  ngOnInit() {
    this.get();
    this.getExchangeCodeManager();
  }

  get(){
    this.loader.state(true);
    const params: any = {
      page: this.currentPage.toString(),
      limit: this.pageSize.toString(),
    };
    if(this.excManager){
      params.userId = this.excManager;
    }
    this.http.get('admin/commission', { params }).subscribe(
      (respone: any) => {
        this.earnigAndPayments = respone.data.items;
        this.totalRecords = respone.data.meta.totalItems;
        this.loader.state(false);
      },
      error => {
        this.loader.state(false);
        this.earnigAndPayments = [];
        this.totalRecords = 0;
      }
    );
  }
  onPageChange(currentPage){
    this.currentPage = currentPage;
    this.get();
  }

  getExchangeCodeManager(){
    this.http.get('admin/user/list/exchange-code-manager').subscribe(
      (respone: any) => {
        this.exchangeCodeManagers = respone.data;
      },
      error => {
      }
    );
  }

  getByExcManager(){
    this.get();
  }

  onClickSettle(commissionId: number){
    if (confirm("Are you sure?")) {
      this.loader.state(true);
      this.http.put('admin/commission/settle/' + commissionId, {}).subscribe(
        (respone: any) => {
          this.get();
          this.loader.state(false);
        },
        error => {
          this.loader.state(false);
        }
      );
    }
  }
}
