import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guest-layout-background',
  templateUrl: './guest-layout-background.component.html',
  styleUrls: ['./guest-layout-background.component.scss']
})
export class GuestLayoutBackgroundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
