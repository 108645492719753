import { Component, OnInit } from "@angular/core";
import { LoaderService } from "src/app/services/loader";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment.prod";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  bookData: any;
  UsersData: any;
  freeBookData: any;
  totalBooks: any;
  totalUsers: any;
  userDataDashboards: any;
  revenueDataDashboards: any;
  customerDataDashboards: any;
  dataDashboards: any;
  imgUrl = environment.imgUrl;
  summaryEarningAndExchangerNumber: any = {
    earAmount: 0,
    exchangerNumber: 0,
    date: "",
  };

  constructor(private loader: LoaderService, private http: HttpClient) {}

  ngOnInit() {
    // this.getAllBooks();
    // this.getAllUsers();
    // this.getFreeBooks();
    // this.summaryEarningAmountAndExchangeNumber();
    this.getAllDashboardData();
  }
  getAllBooks() {
    this.loader.state(true);
    this.http.get("admin/book?" + "limit=5").subscribe(
      (respone: any) => {
        this.loader.state(false);
        this.bookData = respone.data.items;
        this.totalBooks = respone.data.meta.totalItems;
      },
      (error) => {
        this.loader.state(false);
      }
    );
  }

  getAllDashboardData() {
    this.loader.state(true);
    this.http.get("admin/dashboard").subscribe(
      (respone: any) => {
        this.loader.state(false);
        this.userDataDashboards = respone.lastThreeMonthsRegisteredUsers;
        this.revenueDataDashboards = respone.purchasedAndSubscribed;
        this.customerDataDashboards = respone.purchasedAndSubscribed;
        this.dataDashboards = respone;
      },
      (error) => {
        this.loader.state(false);
      }
    );
  }

  getFreeBooks() {
    this.loader.state(true);
    const params = {
      type: "free",
    };
    this.http.get("admin/book?" + "limit=5", { params }).subscribe(
      (respone: any) => {
        this.loader.state(false);
        this.freeBookData = respone.data.items;
        this.freeBookData.forEach((book) => {
          book.isActive = book.type === "free" ? true : false;
        });
      },
      (error) => {
        this.loader.state(false);
      }
    );
  }
  getAllUsers() {
    this.loader.state(true);
    this.http.get("admin/user?" + "limit=5").subscribe(
      (respone: any) => {
        this.loader.state(false);
        this.UsersData = respone.data.items;
        this.totalUsers = respone.data.meta.totalItems;
        this.UsersData.forEach((user) => {
          user.isActive = user.status === "active" ? true : false;
        });
      },
      (error) => {
        this.loader.state(false);
      }
    );
  }
  changeStatus(user: any, index: any) {
    const orginalStatus = user.status;
    const postStatus = user.status === "active" ? "inactive" : "active";

    if (confirm("Do you want to " + postStatus + " this user?")) {
      this.loader.state(true);
      this.http.put("admin/user/" + user.id, { status: postStatus }).subscribe(
        (data: any) => {
          this.loader.state(false);
          this.getAllUsers();
        },
        (error) => {
          this.loader.state(false);
        }
      );
    } else {
      setTimeout(() => {
        user.isActive = orginalStatus === "active" ? true : false;
      }, 2);
    }
  }
  changeFreeBookStatus(book: any, index: any) {
    const orginalStatus = book.type;
    const postStatus = book.type === "free" ? "paid" : "free";

    if (confirm("Do you want to change book status?")) {
      console.log({ type: postStatus });
      this.loader.state(true);
      this.http.put("admin/book/" + book.id, { type: postStatus }).subscribe(
        (data: any) => {
          this.loader.state(false);
          this.getFreeBooks();
        },
        (error) => {
          this.loader.state(false);
        }
      );
    } else {
      setTimeout(() => {
        book.isActive = orginalStatus === "free" ? true : false;
      }, 2);
    }
  }

  summaryEarningAmountAndExchangeNumber() {
    this.http.get("admin/user-exchange/report/earnig-exchanger").subscribe(
      (respone: any) => {
        this.summaryEarningAndExchangerNumber = respone.data;
      },
      (error) => {}
    );
  }
}
