import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  chapters:any;
  categoryDrag:any=[];
  public Categories: any;
  addCategory: FormGroup;
  categoryTitle = 'Add Category';
  categoryId: any;
  searchData: any;
  id: any;
  totalCategories: any;
  data: any;
  currentPage = 1;
  pageSize = 50;
  constructor(
    private loader: LoaderService,
    private http: HttpClient,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getAllCategory();
    this.addCategory = this.fb.group({
      name: ['', [Validators.required]]
    });
  }
  drop(event: CdkDragDrop<any>) {
    this.categoryDrag=[];
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      }
    for(let i=0;i<event.container.data.length;i++){
      const chapters = {
        id: event.container.data[i].ct_id,
        order: i
      }
      this.categoryDrag.push(chapters);
    }
    this.http.put('admin/category/update/order', { 'orders': this.categoryDrag }).subscribe(
      (data: any) => {
        this.loader.state(false);
      },
      error => {
        this.loader.state(false);
      }
    );
  }

  getAllCategory(search: string = this.searchData) {
    this.loader.state(true);
    const params = {
      name: '',
      page: this.currentPage.toString(),
      limit: this.pageSize.toString()
    }
    if (search) {
      params.name = search
    }
    this.http.get('admin/category', { params }).subscribe(
      (respone: any) => {
        this.loader.state(false);
        this.Categories = respone.data.items;
        this.totalCategories = respone.data.meta.totalItems;
      },
      error => {
        this.loader.state(false);
      }
    );
  }

  openCategoeyModal(addCategories) {
    this.addCategory.get('name').setValue('');
    this.categoryTitle = 'Add Category';
    this.modalService.open(addCategories, { centered: true});
  }
  addCategoriesValue() {
    if (this.categoryId != undefined) {
      this.loader.state(true);
      this.http.put('admin/category/' + this.categoryId, this.addCategory.value).subscribe(
        (data: any) => {
          this.loader.state(false);
          this.addCategory.get('name').setValue('');
          this.modalService.dismissAll();
          delete this.categoryId;
          this.getAllCategory();
        },
        error => {
          this.loader.state(false);
        }
      );
    } else {
      this.loader.state(true);
      console.log(this.addCategory.value)
      this.http.post('admin/category', this.addCategory.value).subscribe(
        (data: any) => {
          this.loader.state(false);
          this.addCategory.get('name').setValue('');
          this.modalService.dismissAll();
          this.getAllCategory();
        },
        error => {
          this.loader.state(false);
        }
      );
    }
  }
  deleteCategory(catId: string, name: string) {
    if (confirm("Are you sure you want to delete " + '"' + name + '"' + "?")) {
      this.loader.state(true);
      this.http.delete('admin/category/' + parseInt(catId)).subscribe(
        data => {
          this.loader.state(false);
          this.getAllCategory();
        },
        error => {
          this.loader.state(false);
        }
      );
    }

  }
  editCategory(addCategories, category) {
    this.categoryTitle = 'Edit Category';
    this.categoryId = category.ct_id;
    this.addCategory.get('name').setValue(category.ct_name);
    this.modalService.open(addCategories, { centered: true});
  }
  Search(data) {
    this.getAllCategory(data);
  }
  SearchOnChange(data) {
    if (data == '') {
      this.getAllCategory(data);
    }
  }
}
